import { createSelector } from '@reduxjs/toolkit'

export const currenciesStore = (state) => state.currencies

export const selectCurrencies = createSelector(
  currenciesStore,
  (currencies) => {
    return currencies.currencies
  }
)
